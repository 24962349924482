<template>
  <div class="Lookinvite">
    <div class="top-title">
      <div class="top-text">招聘信息列表</div>

      <el-form ref="form" label-width="100px">
        <div class="top-box">
          <el-form-item label="标题">
            <el-input v-model="seeks.a"></el-input>
          </el-form-item>

          <el-form-item label="工种">
            <el-input v-model="seeks.b"></el-input>
          </el-form-item>

          <el-button
            type="primary"
            class="biy-icon"
            icon="el-icon-check"
            @click="seek()"
            >搜索</el-button
          >
        </div>
      </el-form>
    </div>

    <div class="table-tap">
      <ul>
        <li
          v-for="(item, i) in taplist"
          :key="i"
          v-show="baseGetSeparate == 1 && item.tapid == 0 ? false : true"
        >
          <span
            @click="tap(item)"
            :class="classactive == item.tapid ? 'active' : ''"
            >{{ item.tapname }}</span
          >
        </li>
      </ul>
    </div>
    <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading">
        <el-table-column label="序号" width="70px">
          <template slot-scope="scope"> {{ scope.$index + 1 }} </template>
        </el-table-column>
        <el-table-column prop="headline" label="标题"> </el-table-column>
        <el-table-column prop="companyName" label="工厂名称"> </el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column prop="kind" label="工种"> </el-table-column>
        <el-table-column prop="salary" label="工资"> </el-table-column>
        <el-table-column prop="recruitingNumbers" label="招聘人数">
        </el-table-column>
        <el-table-column prop="is_push" label="是否推荐">
          <template slot-scope="scope">
            <el-checkbox
              :checked="scope.row.is_push == '0' ? false : true"
              @change="tuijian(scope.row.id, scope.row.is_push)"
              >{{ scope.row.is_push == "0" ? "未推荐" : "已推荐" }}</el-checkbox
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="xiangqing(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="scope.row.statusCode == 1 ? false : true"
              >详情</el-button
            >
            <el-button
              @click="yanzhan(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="
                scope.row.statusCode == 2 &&
                scope.row.status.indexOf('下架') === -1
                  ? true
                  : false
              "
              >延展</el-button
            >
            <el-button
              @click="xiangqing(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="
                scope.row.statusCode == 2 &&
                scope.row.status.indexOf('下架') === -1
                  ? true
                  : false
              "
              >下架</el-button>
            <el-button
              @click="shenhe(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="scope.row.statusCode == 1 ? true : false"
              >{{ baseGetSeparate == 1 ? "审核" : "详情" }}</el-button>
            <el-button
              @click="xiugai(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="
                baseGetSeparate == 2 &&
                (scope.row.statusCode == 3 || scope.row.statusCode == 0) &&
                scope.row.status.indexOf('下架') === -1
                  ? true
                  : false
              "
              >修改</el-button
            >
            <el-button
              @click="shanchu(scope.row)"
              class="table-form-btn"
              type="text"
              v-if="scope.row.status.indexOf('下架') !== -1 ? true : false"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="延展日期" :visible.sync="dialogFormVisible" width="450px">
      <el-form :model="listpar" label-width="150px">
        <el-form-item label="起始工作日期">
          <el-date-picker
            v-model="listpar.startTime"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :disabled="true"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束工作日期">
          <el-date-picker
            v-model="listpar.finishTime"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :disabled="true"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="修改结束工作日期">
          <el-date-picker
            v-model="listpar.finishTimes"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions()"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="yanzhanxiugai()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js";
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js";
export default {
  data() {
    return {
      // 判断商家端和会员端
      baseGetSeparate: this.$common.baseGetSeparate(),
      // 上传图片的地址
      upLoadUrl: config.ImgUrl,
      // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,

      // 弹窗
      dialogFormVisible: false,

      // 下拉框
      seeks: {},
      classactive: 99,

      taplist: fakelist.lookinvite.two,

      list: [],
      listpar: {},
    };
  },
  created() {
    this.getSong();
    
  },
  mounted() {},

  methods: {
    //查询接口
    async getSong() {
      let res = await apiurl.fetchPageData({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog: "RecruitPlaterA",
        query: {
          title: this.seeks.a,
          workType: this.seeks.b,
          statusCode: this.classactive,
        },
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.list = res.Result.pageData;
      this.total = res.Result.count;
      this.loading = false;
    },

    // 搜索栏
    seek() {
      this.currentPage = 1;
      this.getSong();
    },

    // 选项卡
    tap(e) {
      this.currentPage = 1;
      this.classactive = e.tapid;
      this.getSong();
    },

    //延展弹窗
    yanzhan(row) {
      this.listpar = row;
      this.dialogFormVisible = true;
    },

    //延展修改
    async yanzhanxiugai() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "extend",
        bizId: this.listpar.id,
        finishtime: this.listpar.finishTimes,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.dialogFormVisible = false;
      this.getSong();
      this.$alert("延展成功", "消息提示", { confirmButtonText: "确定" });
    },

    //弹窗
    shanchu(row) {
      this.listpar = row;
      this.$confirm("是否确认删除", "消息提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消操作",
      })
        .then(() => {
          this.shanchus();
        })
        .catch((action) => {
          console.log("取消");
        });
    },

    //删除
    async shanchus() {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "delete",
        bizId: this.listpar.id,
        finishtime: this.listpar.finishTimes,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.dialogFormVisible = false;
      this.getSong();
      this.$alert("删除成功", "消息提示", { confirmButtonText: "确定" });
    },

    // 驳回跳转修改页
    xiugai(row) {
      this.$router.push({ path: `/home/newsinvitepar/${row.id}` });
    },

    // 详情界面
    xiangqing(row) {
      this.$router.push({ path: `/home/lookinvitepar/${row.id}` });
    },
    // 跳转审核页
    shenhe(row) {
      this.$router.push({ path: `/home/Auditservepar/${row.id}` });
    },

    //日期控件
    pickerOptions() {
      return {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.listpar.finishTime).getTime();
        },
      };
    },

    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },

    //推荐
    async tuijian(id, isPush) {
      let res = await apiurl.entityDataHandle({
        FOREND_TOKEN: this.$common.baseGetToken(), //统一传递一下前端token
        loginName: this.$common.baseGetuSername(), //登录账号
        loginType: this.$common.baseGetSeparate(), //区分用户和商家1用户2商家

        bizCatalog: "RecruitEntity",
        handleMode: "is_push",
        is_push: isPush == "0" ? 1 : 0,
        bizId: id,
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      if (isPush == "0") {
        this.$alert("成功推荐", "消息提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.getSong();
          },
        });
      } else {
        this.$alert("成功取消推荐", "消息提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.getSong();
          },
        });
      }
    },
  },
  computed: {},
};
</script>


<style  scoped>
.table-tap {
  width: 98%;
  margin: 0 auto;
  padding: 30px 0;
  border-bottom: 2px solid rgb(255, 187, 0);
}
.table-tap ul {
  display: flex;
  justify-content: left;
}
.table-tap ul li {
  width: 150px;
  text-align: center;
}
.table-tap ul li span {
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
}
.table-tap ul li span.active {
  color: rgb(255, 187, 0);
  font-size: 16px;
}

.Issuemessage-box {
  width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}
@import "../../assets/css/liststyle.css";
</style>